import React from 'react'
import { Result, Icon } from 'antd-mobile';
const ResultPage = (props) => {
    let [lang,status] = props.location.search.split('&').map(item=>item.split('=')[1])
    if(lang==='ch'){
        if(status==='success'){
            return (
                <Result
                    img={<Icon type="check-circle" className="spe" style={{ fill: '#1F90E6' }} />}
                    title="添加成功"
                    message="请您牢记6位数字支付密码, 便于以后付款时需提供此密码"
                />
            )
        }else{
            let message = '银行卡信息有误, 请重试'
            if(status==='failed_0'){
                message = "您的银行卡类型不支持此付款方式"
            }
            if(status==='failed_1'){
                message = "该卡因未知原因被拒，请联系发卡机构以获取更多信息."
            }
            if(status==='failed_2'){
                message = "您的银行卡已存在, 请勿重复添加"
            }
            if(status==='failed_cvc'){
                message = "您输入的CVC有误, 请核实后重试"
            }
            if(status==='failed_expired'){
                message = "您的银行卡已过期"
            }
            return (
                <Result
                    img={<Icon type="cross-circle-o" className="spe" style={{ fill: '#F13642' }} />}
                    title="添加失败"
                    message={message}
                />
            )
        }
    }else{
        if(status==='success'){
            return (
                <Result
                    img={<Icon type="check-circle" className="spe" style={{ fill: '#1F90E6' }} />}
                    title="Successfully"
                    message="Credit card is ready to pay by using 6-digit payment code"
                />
            )
        }else{
            let message = 'Credit card is invalid'
            if(status==='failed_0'){
                message = "Your card is not supported."
            }
            if(status==='failed_1'){
                message = "The card has been declined for an unknown reason, please contact the card issuer for more information."
            }
            if(status==='failed_2'){
                message = "The card is already existing."
            }
            if(status==='failed_cvc'){
                message = "Your card CVC is incorrect."
            }
            if(status==='failed_expired'){
                message = "Your card has expired."
            }
            return (
                <Result
                    img={<Icon type="cross-circle-o" className="spe" style={{ fill: '#F13642' }} />}
                    title="Failed"
                    message={message}
                />
            )
        }
    }
    
}

export default ResultPage