import React from 'react';
import {Elements} from 'react-stripe-elements';

import InjectedCheckoutForm from './CheckoutForm';

class MyStoreCheckout extends React.Component {
  render() {
    let {userId} = this.props.match.params
    let lang = this.props.location.search.split('=')[1]
    return (
      <Elements>
        <InjectedCheckoutForm userId={userId} lang={lang}/>
      </Elements>
    );
  }
}

export default MyStoreCheckout;