import React from 'react';
import { injectStripe, CardElement } from 'react-stripe-elements';
import { withRouter } from 'react-router-dom'
import Request from './request'
import { Button, Toast } from 'antd-mobile'

class CheckoutForm extends React.Component {
  state = {
    processing: false,
    paymentCode: '',
    errorText: '',
    errorText_all: '',
    errorCode: '',
    el:''
  }
  handleChange = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]+/g, '')
    if (e.target.value.length > 6) {
      e.target.value = parseInt(e.target.value.toString().substring(0, 6))
    }
    this.setState({
      paymentCode: e.target.value
    })
    if (e.target.value.length !== 6) {
      let text = this.props.lang === 'ch' ? "支付密码必须为6位数字" : "Payment code is invalid (6-digit)"
      this.setState({
        errorCode: text
      })
    } else {
      this.setState({
        errorCode: ''
      })
    }
  }
  handleSubmit = async (ev) => {

    ev.preventDefault()
    if (this.state.errorText||this.state.errorText_all) {
      let text = this.props.lang === 'ch' ? '请正确填写银行卡信息' : 'Card information is incomplete'
      return Toast.fail(text, 1)
    }
    if (this.state.paymentCode.length !== 6) {
      let text = this.props.lang === 'ch' ? '请设置6位数字支付密码' : 'Payment code is invalid'
      return Toast.fail(text, 1)
    }
    const cardElement = this.props.elements.getElement('card')
    this.setState({
      processing: true
    })
    try {
      let { client_secret } = await this.getClientSecret()
      let intent = await this.props.stripe.confirmCardSetup(client_secret, {
        payment_method: {
          card: cardElement,
        },
      })
      if (intent.error) {
        let err = new Error("银行卡问题")
        if (intent.error.message == 'Your card is not supported.' || intent.error.message == '您的银行卡已被拒绝。') {
          err.code = 0
        }
        if (intent.error.decline_code == 'do_not_honor') {
          err.code = 1
        }
        if (intent.error.code == 'incorrect_cvc') {
          err.code = 'cvc'
        }
        if (intent.error.code == 'expired_card') {
          err.code = 'expired'
        }
        throw err
      }
      await this.saveCard(this.props.userId, intent.setupIntent)
      this.setState({
        processing: false
      })
      return this.props.history.push(`/result-page?lang=${this.props.lang}&status=success`)
    } catch (error) {
      console.log(error)
      this.setState({
        processing: false
      })
      return this.props.history.push(`/result-page?lang=${this.props.lang}&status=failed_${error.code}`)
    }
  }
  getClientSecret = async () => {
    return Request.get(`/client-secret`)
  }
  saveCard = async (userId, intent) => {
    return Request.post(`/add-card`, { userId, intent, code: this.state.paymentCode })
  }
  handleBlur = () =>{
    if(!this.state.el._complete){
      let text = this.props.lang === 'ch' ? "您银行卡信息不完整。" : "Card information is incomplete"
      this.setState({ errorText_all: text })
    }else{
      this.setState({ errorText_all: '' })
    }
  }
  handleCardReady = (el) => {
    el.focus()
    this.setState({el:el})
  }
  handleCardChange = (change) => {
    this.setState({ errorText_all: '' })
    if (change.error) {
      if (change.error.type == "validation_error") {
        if (change.error.code == "incomplete_expiry") {
          let text = this.props.lang === 'ch' ? "您银行卡的到期日期不完整。" : "Expiry date is incomplete"
          return this.setState({ errorText: text })
        }
        if (change.error.code == "incomplete_cvc") {
          let text = this.props.lang === 'ch' ? "您银行卡的安全码不完整。" : "CVC is incomplete"
          return this.setState({ errorText: text })
        }
        if (change.error.code == "incomplete_number") {
          let text = this.props.lang === 'ch' ? "您的银行卡号不完整。" : "Card number is incomplete"
         return this.setState({ errorText: text })
        }
      }
    } else {
     return this.setState({ errorText: '' })
    }
  }
  render() {
    return (
      <form style={{ width: '100%' }}>
        <div>
          <div style={{ padding: '20px', background: 'white', borderRadius: "10px" }}>
            <div style={{ marginBottom: '10px', fontWeight: 'bold' }}>{this.props.lang === 'ch' ? '银行卡信息' : 'Credit Card'}</div>
            <CardElement
              onBlur={this.handleBlur}
              onChange={this.handleCardChange}
              onReady={this.handleCardReady}
              hidePostalCode={true} />
            <div style={{ color: 'red' }}>{this.state.errorText?this.state.errorText:this.state.errorText_all}</div>
            <div style={{ marginTop: '10px', marginBottom: '10px', fontWeight: 'bold' }}>{this.props.lang === 'ch' ? '设置支付密码 (6位数字)' : 'Set Payment Code (6-digit)'}</div>
            <div style={{ marginBottom: '10px', fontSize: '12px' }}>{this.props.lang === 'ch' ? '(此密码用于支付订单时使用, 请牢记此密码)' : '(Please remember this code used for future payment)'}</div>
            <input type="text" className="code-input" style={{ outline: 'none', width: '100%', padding: '5px 10px', boxSizing: 'border-box', border: '1px solid lightgray', borderRadius: '0' }} onChange={this.handleChange}></input>
            <div style={{ color: 'red' }}>{this.state.errorCode}</div>
          </div>
        </div>
        <div style={{ padding: '20px' }}>
          <Button loading={this.state.processing} disabled={this.state.processing} type="primary" style={{ width: '100%' }} onClick={this.handleSubmit}>{this.props.lang === 'ch' ? '提交' : 'Submit'}</Button>
        </div>
      </form>
    );
  }
}

export default injectStripe(withRouter(CheckoutForm))