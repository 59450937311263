import React from 'react';
import { StripeProvider } from 'react-stripe-elements';
import { HashRouter, Route } from 'react-router-dom'
import MyStoreCheckout from './MyStoreCheckout';
import Result from './result'
import FomopayStart from './fomopay/start'
import FomopayReturn from './fomopay/return'
import Fomopay409 from './fomopay/409'
import FomopayTimeout from './fomopay/timeout'
import FomoError from './fomopay/error'
import OmiseStart from './omise/start'
import PaynowTakeaway from './omise/takeaway'
import OmiseError from './omise/error'
class App extends React.Component {

  render() {
    return (
      <HashRouter>
      <Route path='/result-page' component={Result} />
      <Route path='/fomopay-start/:orderId/:client' component={FomopayStart} />
      <Route path='/fomopay-return' component={FomopayReturn} />
      <Route path='/fomopay-409' component={Fomopay409} />
      <Route path='/fomopay-timeout' component={FomopayTimeout} />
      <Route path='/fomopay-error' component={FomoError} />
      <Route path='/paynow-takeaway/:orderId' component={PaynowTakeaway} />
      <Route path='/paynow/:orderId' component={OmiseStart} />
      <Route path='/omise-error' component={OmiseError} />
      </HashRouter>
    );
  }
}

export default App
