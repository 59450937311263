import React from "react";
import { Result, Icon } from "antd-mobile";
const FomoPayReturnPage = () => {
  let uniSdk = null
  setTimeout(() => {
    let targetUrl = "";
    let domain = window.location.href.split("/#/")[0];
    if (domain.includes(".hi-otter.com")) {
      targetUrl = "https://mall.ebuymart.hi-otter.com/#/pages/my/my";
    }
    if (domain.includes(".sg")) {
      targetUrl = "https://mall.ebuymart.sg/#/pages/my/my";
    }
    if(window.location.href.includes('?for=alipay')){
      try {
          return uniSdk.switchTab({
            url:'/pages/my/my'
        })
      } catch (error) {
        window.location.href = targetUrl;
      }
    }
    if(window.location.href.includes('?for=3d')){
      if(window.location.href.includes('&from=app')){
        uniSdk.switchTab({
          url:'/pages/my/my'
        })
      }else{
        window.location.href = targetUrl;
      }
    }else{
      window.location.href = targetUrl;
    }
  }, 3000);
  document.addEventListener('UniAppJSBridgeReady', function () {
    console.log('uni-sdk ok')
    uniSdk = window.uni
  })
  var lang = navigator.language || navigator.userLanguage; //常规浏览器语言和IE浏览器
  lang = lang.substr(0, 2); //截取lang前2位字符
  if (lang == "zh") {
    return (
      <Result
        img={
          <Icon
            type="check-circle"
            className="spe"
            style={{ fill: "#1F90E6" }}
          />
        }
        title="提交成功"
        message="您的支付已成功提交，3秒后将自动跳转"
      />
    );
  } else {
    return (
      <Result
        img={
          <Icon
            type="check-circle"
            className="spe"
            style={{ fill: "#1F90E6" }}
          />
        }
        title="Payment Submitted"
        message="Your payment is submitted successfully, redirecting within 3s"
      />
    );
  }
};

export default FomoPayReturnPage;
