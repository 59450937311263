import React from 'react'
import { Result, Icon } from 'antd-mobile';
const FomoPayTimeoutPage = () => {
    var lang = navigator.language||navigator.userLanguage;//常规浏览器语言和IE浏览器
      lang = lang.substr(0, 2);//截取lang前2位字符
      if(lang == 'zh'){
        return (
            <Result
                img={<Icon type="cross-circle-o" className="spe" style={{ fill: '#F13642' }} />}
                title="页面超时"
                message="请求页面超时，如已成功付款，退出当前页面即可"
            />
        )
      }else{
        return (
            <Result
                img={<Icon type="cross-circle-o" className="spe" style={{ fill: '#F13642' }} />}
                title="Page Timeout"
                message="This page is timeout, if you have paid, just close the page"
            />
        )
      }
}

export default FomoPayTimeoutPage