import React from 'react'
import { Result, Icon } from 'antd-mobile';
const omiseErrorPage = () => {
    var lang = navigator.language||navigator.userLanguage;//常规浏览器语言和IE浏览器
      lang = lang.substr(0, 2);//截取lang前2位字符
      if(lang == 'zh'){
        return (
            <Result
                img={<Icon type="cross-circle-o" className="spe" style={{ fill: '#F13642' }} />}
                title="系统维护中"
                message="Paynow支付暂不可用, 请稍后再试"
            />
        )
      }else{
        return (
            <Result
                img={<Icon type="cross-circle-o" className="spe" style={{ fill: '#F13642' }} />}
                title="System maintenance"
                message="Paynow is currently unavailable, please try again later
                "
            />
        )
      }
}

export default omiseErrorPage