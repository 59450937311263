import React from 'react';
import Request from '../request'
import { CircleLoading } from 'react-loadingg';
class MyStoreCheckout extends React.Component {
  state = {
    payment:{
      merchant:'',
        price:'',
        description:'',
        transaction:'',
        return_url:'',
        callback_url:'',
        currency_code:"sgd",
        type:"sale",
        timeout:3000,
        nonce:'',
        signature:''
    }
  }
  getPayment = async()=>{
    try {
       let { orderId,client } = this.props.match.params
       let {payment} = await Request.get(`/payment?orderId=${orderId}&client=${client}`)
       return payment
    } catch (err) {
       if(err.status===409){
        throw new Error("/fomopay-409")
      }
      if(err.status===400){
        throw new Error("/fomopay-error")
      }
    }
  }
  componentDidMount() {
    document.title = "Fomopay"
    this.getPayment().then(payment=>{
      this.setState({payment:payment})
      document.getElementById("form").submit()
    }).catch(err=>{
      this.props.history.push(`${err.message}`)
    })
  }
  render() {
    return (
      <div>
        <CircleLoading />
        <div className="hide">
        <form id="form" action="https://gateway.fomopay.com/pgw/v1" method="post">
          <input type="text" name="merchant" value={this.state.payment.merchant} />
          <input type="text" name="price" value={this.state.payment.price} />
          <input type="text" name="description" value={this.state.payment.description} />
          <input type="text" name="transaction" value={this.state.payment.transaction} />
          <input type="text" name="return_url" value={this.state.payment.return_url} />
          <input type="text" name="upstreams" value={this.state.payment.upstreams} />
          <input type="text" name="callback_url" value={this.state.payment.callback_url} />
          <input type="text" name="currency_code" value={this.state.payment.currency_code} />
          <input type="text" name="type" value={this.state.payment.type} />
          <input type="text" name="timeout" value={this.state.payment.timeout} />
          <input type="text" name="nonce" value={this.state.payment.nonce} />
          <input type="text" name="signature" value={this.state.payment.signature} />
        </form>
      </div>
      </div>
    );
  }
}

export default MyStoreCheckout;