import axios from 'axios'

const instance = axios.create({
	baseURL:process.env.REACT_APP_SERVER_ADDRESS,
	timeout:60000*3
})

instance.interceptors.response.use(function(response){
	return response.data
},function(error){
	let info = {
		status:error.response.status,
		message:error.response.data.error,
		code:error.response.data.code
	}
	if(info.status===500){
		info.message = '服务器内部错误'
	}
	return Promise.reject(info)
})

export default instance