import React from 'react'
import { Result, Icon } from 'antd-mobile';
const FomoPay409Page = () => {
    var lang = navigator.language||navigator.userLanguage;//常规浏览器语言和IE浏览器
      lang = lang.substr(0, 2);//截取lang前2位字符
      if(lang == 'zh'){
        return (
            <Result
                img={<Icon type="cross-circle-o" className="spe" style={{ fill: '#F13642' }} />}
                title="发生错误"
                message="该订单已经付款,请勿重复支付"
            />
        )
      }else{
        return (
            <Result
                img={<Icon type="cross-circle-o" className="spe" style={{ fill: '#F13642' }} />}
                title="Duplication Error"
                message="The order is already paid"
            />
        )
      }
}

export default FomoPay409Page