import React from 'react';
import Request from '../request'
import { CommonLoading } from 'react-loadingg';
import { Button, WingBlank, Tabs, Carousel } from 'antd-mobile';

const tabs = [{ title: 'UOB' }, { title: 'OCBC' }, { title: 'DBS' }]

class OmiseClient extends React.Component {
    state = {
        guides: [{ way: 'UOB', images_ch: [], images_en: [] }, { way: 'OCBC', images_ch: [], images_en: [] }, { way: 'DBS', images_ch: [], images_en: [] }],
        qrcode: ''
    }
    getAmount = async () => {
        try {
            let { orderId } = this.props.match.params
            let { amount,qrcode } = await Request.get(`/amount?orderId=${orderId}`)
            return { amount,qrcode, orderId }
        } catch (err) {
            if (err.status === 409) {
                throw new Error("409")
            }
        }
    }
    getQRcode = async (amount, source, orderId) => {
        let qrcode = await Request.get(`/paynow-qrcode?amount=${amount}&source=${source}&orderId=${orderId}`)
        return qrcode
    }
    getGuides = async () => {
        let { guides } = await Request.get('/bankGuides')
        return guides
    }
    checkStatus = async (orderId) => {
        let { status } = await Request.get(`/paynow-result?orderId=${orderId}`)
        if (status == 'pending') {
            return await this.checkStatus(orderId)
        } else if (status == 'success') {
            return this.props.history.push(`/fomopay-return`)
        }
    }
    componentDidMount() {
        this.getGuides().then(res => {
            this.setState({ guides: res })
        })
        const { Omise } = window
        Omise.setPublicKey(process.env.REACT_APP_STRIPE_KEY_OMISE);
        this.getAmount().then(async r => {
            if(!r.qrcode){
                Omise.createSource('paynow', {
                    "amount": r.amount,
                    "currency": "SGD"
                }, (statusCode, response) => {
                    let source = response.id
                    this.getQRcode(r.amount, source, r.orderId).then(async rs => {
                        if(rs.error&&!rs.qrcode){
                           return this.props.history.push(`/omise-error`)
                        }else{
                            this.setState({
                                qrcode: rs.qrcode
                            })
                            await this.checkStatus(r.orderId)
                        }
                    })
                });
            }else{
                this.setState({
                    qrcode: r.qrcode
                })
                await this.checkStatus(r.orderId)
            }
        }).catch(err => {
            if(err.message=='409'){
                this.props.history.push(`/fomopay-return`)
            }else{
                this.props.history.push(`/fomopay-timeout`)
            }
        })
    }
    render() {
        if (this.state.qrcode) {
            let lang = this.props.location.search.split('=')[1]
            if (lang == 'ch') {
                return (
                    <WingBlank>
                        <div style={{ paddingBottom: '20px' }}>
                            <div style={{ paddingTop: '10px' }}>
                                <div style={{ padding: '10px', fontWeight: 'bold', fontSize: '16px' }}>使用二维码进行一次 PayNow 转账</div>
                                <div style={{ padding: '0 10px', fontSize: '12px', color: 'rgba(0,0,0,0.7)', lineHeight: '16px' }}>通过保存二维码并上传到银行应用程序的 PayNow 页面进行转账</div>
                            </div>
                            <div style={{ textAlign: 'center', paddingTop: '15px' }}>
                                <img src={this.state.qrcode} style={{ width: '80%' }} />
                            </div>
                            <div style={{ padding: '10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ fontWeight: 'bold' }}>第 1 步：</div>
                                    <div>将此二维码保存到您的照片中</div>
                                </div>
                            </div>
                            <div style={{ padding: '10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ fontWeight: 'bold' }}>第 2 步：</div>
                                    <div>请前往银行应用程序的二维码扫描页面</div>
                                </div>
                            </div>
                            <div style={{ padding: '10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ fontWeight: 'bold' }}>第 3 步：</div>
                                    <div>上传您的二维码以完成转账</div>
                                </div>
                            </div>
                            <div style={{ padding: '10px' }}>
                                <Button type="primary">长按上方二维码以保存至照片</Button>
                            </div>
                            <div style={{ padding: '10px 10px 20px 10px', fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>
                                PayNow 付款演示
                            </div>
                            <Tabs tabs={tabs}
                                initialPage={0}
                                swipeable={false}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'auto', backgroundColor: '#fff' }}>
                                    {
                                        this.state.guides.find(g => g.way == 'UOB').images_ch.map(src => (
                                            <div key={src}
                                                style={{ display: 'inline-block', width: '100%', height: 'auto', textAlign: 'center' }}
                                            >
                                                <img
                                                    src={src}
                                                    alt=""
                                                    style={{ width: '100%', verticalAlign: 'top' }}
                                                />
                                            </div>
                                        ))}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'auto', backgroundColor: '#fff' }}>
                                    {
                                        this.state.guides.find(g => g.way == 'OCBC').images_ch.map(src => (
                                            <div key={src}
                                                style={{ display: 'inline-block', width: '100%', height: 'auto', textAlign: 'center' }}
                                            >
                                                <img
                                                    src={src}
                                                    alt=""
                                                    style={{ width: '100%', verticalAlign: 'top' }}
                                                />
                                            </div>
                                        ))}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'auto', backgroundColor: '#fff' }}>
                                    {
                                        this.state.guides.find(g => g.way == 'DBS').images_ch.map(src => (
                                            <div key={src}
                                                style={{ display: 'inline-block', width: '100%', height: 'auto', textAlign: 'center' }}
                                            >
                                                <img
                                                    src={src}
                                                    alt=""
                                                    style={{ width: '100%', verticalAlign: 'top' }}
                                                />
                                            </div>
                                        ))}
                                </div>
                            </Tabs>
                        </div>
                    </WingBlank>
                )
            } else {
                return (
                    <WingBlank>
                        <div style={{ paddingBottom: '20px' }}>
                            <div style={{ paddingTop: '10px' }}>
                                <div style={{ padding: '10px', fontWeight: 'bold', fontSize: '16px' }}>Make a PayNow transfer with QR code</div>
                                <div style={{ padding: '0 10px', fontSize: '12px', color: 'rgba(0,0,0,0.7)', lineHeight: '16px' }}>Pay by saving and uploading a QR code to your bank app's Paynow page.</div>
                            </div>
                            <div style={{ textAlign: 'center', paddingTop: '15px' }}>
                                <img src={this.state.qrcode} style={{ width: '80%' }} />
                            </div>
                            <div style={{ padding: '10px' }}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ fontWeight: 'bold', width: '64px', flexShrink: 0 }}>Step 1：</div>
                                    <div style={{ flex: 1 }}>Save this QR code to your photos</div>
                                </div>
                            </div>
                            <div style={{ padding: '10px' }}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ fontWeight: 'bold', width: '64px', flexShrink: 0 }}>Step 2：</div>
                                    <div style={{ flex: 1 }}>Go to the QR scanner on your banking app</div>
                                </div>
                            </div>
                            <div style={{ padding: '10px' }}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ fontWeight: 'bold', width: '64px', flexShrink: 0 }}>Step 3：</div>
                                    <div style={{ flex: 1 }}>Upload your QR code to complete your payment</div>
                                </div>
                            </div>
                            <div style={{ padding: '10px' }}>
                                <Button type="primary">Long-Press above QR code for saving</Button>
                            </div>
                            <div style={{ padding: '10px 10px 20px 10px', fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>
                                PayNow Demo
                            </div>
                            <Tabs tabs={tabs}
                                initialPage={0}
                                swipeable={false}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'auto', backgroundColor: '#fff' }}>
                                    {
                                        this.state.guides.find(g => g.way == 'UOB').images_ch.map(src => (
                                            <div key={src}
                                                style={{ display: 'inline-block', width: '100%', height: 'auto', textAlign: 'center' }}
                                            >
                                                <img
                                                    src={src}
                                                    alt=""
                                                    style={{ width: '100%', verticalAlign: 'top' }}
                                                />
                                            </div>
                                        ))}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'auto', backgroundColor: '#fff' }}>
                                    {
                                        this.state.guides.find(g => g.way == 'OCBC').images_ch.map(src => (
                                            <div key={src}
                                                style={{ display: 'inline-block', width: '100%', height: 'auto', textAlign: 'center' }}
                                            >
                                                <img
                                                    src={src}
                                                    alt=""
                                                    style={{ width: '100%', verticalAlign: 'top' }}
                                                />
                                            </div>
                                        ))}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'auto', backgroundColor: '#fff' }}>
                                    {
                                        this.state.guides.find(g => g.way == 'DBS').images_ch.map(src => (
                                            <div key={src}
                                                style={{ display: 'inline-block', width: '100%', height: 'auto', textAlign: 'center' }}
                                            >
                                                <img
                                                    src={src}
                                                    alt=""
                                                    style={{ width: '100%', verticalAlign: 'top' }}
                                                />
                                            </div>
                                        ))}
                                </div>
                            </Tabs>
                        </div>
                    </WingBlank>
                )
            }
        }
        return (
            <div>
                <CommonLoading />
            </div>
        );
    }
}

export default OmiseClient;